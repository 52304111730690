<template>
    <div class="menu-list">
        <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click="clickMenuItem({ item, index })">
            <img class="menu-item-icon" :src="item.icon" alt="">
            <div class="menu-item-text">{{ item.name }}</div>
        </div>

    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                menuList: [],
            }
        },
        computed: {
        },
        mounted() {
            this.createMenus()
        },
        methods: {
            createMenus() {
                let list = [
                    {
                        name: '四库企业',
                        icon: require('@/assets/images/enterprise/icons/menu_siku.png'),
                        path: '/qualification/index',
                    },
                    {
                        name: '诚信数据',
                        icon: require('@/assets/images/enterprise/icons/menu_integrity.png'),
                        path: '',
                    },
                    {
                        name: '人员数据',
                        icon: require('@/assets/images/enterprise/icons/menu_person.png'),
                        path: '/personnel/index',
                    },
                    {
                        name: '项目数据',
                        icon: require('@/assets/images/enterprise/icons/menu_project.png'),
                        path: '/project/index',
                    },
                    {
                        name: '电子证照',
                        icon: require('@/assets/images/enterprise/icons/menu_eletronic.png'),
                        path: '/certificate/index',
                    },
                    {
                        name: '省库企业',
                        icon: require('@/assets/images/enterprise/icons/menu_shengku.png'),
                        path: '/record/index',
                    },
                    {
                        name: '水利水电',
                        icon: require('@/assets/images/enterprise/icons/menu_shuili.png'),
                        path: '',
                    },
                    {
                        name: '公路水运',
                        icon: require('@/assets/images/enterprise/icons/menu_gonglu.png'),
                        path: '',
                    },
                    {
                        name: '石油化工',
                        icon: require('@/assets/images/enterprise/icons/menu_shiyong.png'),
                        path: '',
                    },
                    {
                        name: '国网电力',
                        icon: require('@/assets/images/enterprise/icons/munu_guowang.png'),
                        path: '',
                    },

                ]
                this.menuList = list
            },
            clickMenuItem({ item, index }) {
                if (!item.path) {
                    return this.$message({
                        message: '功能开发中,敬请期待~',
                        type:'warning',
                        center: true
                    });
                }
                this.$router.push({ path: item.path, query: {  } })

                console.log('----点击菜单', item)
            }
        },

    }
</script>

<style scoped>
    .menu-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .menu-item {
        box-sizing: border-box;
        height: 100px;
        width: 19.2%;
        margin-right: 1%;
        margin-top: 20px;
        border: 1px solid #E2E5EC;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .menu-item:hover {
        border: 1px solid #007EF2;
    }

    .menu-item:nth-child(5n+0) {
        margin-right: 0px;
    }

    .menu-item-icon {
        width: 40px;
        height: 40px;
    }

    .menu-item-text {
        font-size: 20px;
        font-weight: 500;
        color: #212121;
        margin-left: 10px;
    }
</style>
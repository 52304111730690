<template>
    <div>
        <div class="border-box">
            <div style="font-size: 16px;display:flex ;justify-content: space-between;">
                <div style="font-weight: bold;">招投标公告（{{!provinceBid?'全国':provinceBid}}）</div>
                <div style="font-size: 14px;color: #888888;cursor: pointer;" @click="more">更多>></div>
            </div>

            <div style="display: flex;margin-top: 20px;">
                <div style="width: 400px;">
                    <el-image class="bannerImg" v-for="(item,index) in adList" :key="index" :src="item.logo"></el-image>

                </div>

                <div style="flex:1;margin-left: 40px;">
                    <div class="" style="display: flex;align-items: center;font-size: 16px;">
                        <div style="margin: 14px;width: 64px;text-align: center;padding-bottom: 10px;"
                            :class="typeIndex===index&&'active'" @click="selectType({item,index})"
                            v-for="(item,index) in typeList" :key="index">{{item.name}}</div>
                    </div>

                    <div>
                        <div class="scrollViewY"
                            style="background: #FBFBFB;padding-left: 20px;padding-right: 20px;padding-top: 20px;height: 560px;">
                            <div v-for="(item,index) in listData" :key="index" @click="goDetail({item})"
                                style="padding-bottom: 16px;margin-bottom: 16px; border-bottom: solid 1px #E2E5EC;display: flex;">
                                <div class="dayBox">
                                    <span style="font-size: 24px; font-weight: bold;">{{item.publishDate|day()}}</span>
                                    <span style="font-size: 14px; ">{{item.publishDate|year()}}</span>
                                </div>
                                <div style="display: flex;align-items: center;flex: 1;">
                                    <div style="flex: 1;">
                                        <div style="display: flex;">
                                            <!-- <div class="tag">预告</div> -->
                                            <div class="hideline1"
                                                style="font-size: 16px;font-weight: bold;max-width: 700px;">
                                                {{ item.customtitle || item.projectName }}</div>
                                        </div>
                                        <div
                                            style="margin-top: 12px;margin-bottom: 8px;font-size: 14px;text-align: left;">
                                            招标单位：{{item.bidCompany||'-'}}</div>
                                        <div style="font-size: 14px;text-align: left;">
                                            招标区域：{{item.province||'-'}}-{{item.city||'-'}}{{item.county?'-'+item.county:''}}
                                        </div>

                                        <div style="display: flex;margin-top: 10px;">
                                            <div class="addres">{{item.province||'-'}}{{item.city?'-'+item.city:''}}
                                            </div>
                                            <div class="addres" style="margin-left: 20px;">{{item.industry||'-'}}</div>
                                        </div>
                                    </div>
                                    <div style="font-weight: bold;color: #888888;">></div>
                                </div>
                            </div>
                        </div>

                        <!-- <div style="background: #FFFFFF;color: #888888;text-align: center;padding: 10px 0;">上划加载更多</div> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getNotiesBid, getlistPage, getCatory } from "@/api/bid.js"
    export default {
        data() {
            return {
                typeList: [{ name: '招标公告', value: '' },
                    // { name: '中标公告', value: '' }
                ],
                typeIndex: 0,
                listData: [],
                adList: [],
                current: 1,
                size: 4,
            };
        },
        filters: {
            day(date) {
                if (!date) return '-'
                let str = date.replaceAll('-', '/')
                let time = str.split(' ')[0]
                return time.substr(8, 9)
            },
            year(date) {
                if (!date) return '-'
                let str = date.replaceAll('-', '/')
                let time = str.split(' ')[0]
                return time.substr(0, 7)
            },

        },
        created() {
            this.getCatory()
            this.getListData()
        },
        props: {
            provinceBid: {
                type: String,
                default: () => {
                    return ''
                }
            },
            provinceAdcode: {
                type: String,
                default: () => {
                    return ''
                }
            },
        },
        watch: {
            provinceBid: {
                handler(newsVal, oldVal) {

                    this.getListData()
                },
                immediate: true
            }
        },
        methods: {
            more() {
                let vRouter = this.$router.resolve({
                    path: '/recruit/index',
                    params: {},
                });
                console.log("vRouter", vRouter);
                window.open(vRouter.href, "_blank");


            },
            goDetail({ item }) {
                
                this.$router.push({path: "/details/index", query: { tenderingCode: item.tenderingCode }});
            },
          
            async getCatory() {
                try {
                    const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
                    let list = data.filter((item) => item.type === "Notice");
                    this.adList = list[0]?.child || []
                } catch (error) {
                    console.log("ee", error);
                }
            },
            async getListData() {
                try {
                    let params = {

                        current: this.current,
                        size: this.size,
                        industry: "工程建设",
                        noticeNature: "招标公告",
                        province: this.provinceBid === '全国' ? '广西壮族自治区' : this.provinceBid,//this.provinceBid==='全国'?'广西壮族自治区':this.provinceBid,


                    }
                    let { data } = await getNotiesBid(params)
                    this.listData = data.records
                } catch (error) {

                }
            },
            selectType({ item, index }) {
                this.typeIndex = index
            },
        }
    };
</script>

<style scoped>
    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .theme-color {
        color: #1890ff;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        color: #212121;
        margin-top: 20px;

    }

    .bannerImg {
        width: 400px;
        height: 300px;
        margin-bottom: 20px;
    }

    .active {
        color: #1890FF;
        border-bottom: solid 3px #1890FF;

    }

    .dayBox {
        background: #FFFFFF;
        color: #1890FF;
        border-radius: 8px;
        border: solid 1px #9CD1FF;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        width: 80px;
        height: 80px;
    }

    .tag {

        padding: 4px;
        background: #FF9E00;
        color: #FFFFFF;
        flex-shrink: 0;
        font-size: 14px;
        margin-right: 10px;
    }

    .addres {
        background: #8094C4;

        padding: 4px;

        color: #FFFFFF;
        flex-shrink: 0;
        font-size: 14px;
    }

    .scrollViewY {
        overflow: hidden;
        overflow: scroll;
        overflow-x: hidden;
        height: 400px;
    }

    .scrollViewY::-webkit-scrollbar {
        width: 0px;

    }
</style>
<template>
    <div>
        <div class="border-box">
            <div class="tab-item" style="font-size: 16px;">
                <div class="theme-color">全国</div>
                <div style="margin-left: 22px">人员资质（2023）</div>
            </div>

            <div style="display: flex">
                <div class="borderRight">
                    <div class="tab-item " :class="index===typeIndex&&'select-item theme-color'"
                        v-for="(item, index) in qualificationList" :key="index" @click="selectType({item,index})">
                        <img v-if="index===typeIndex"  style="width: 14px;height: 14px;margin-right: 15px;" :src="item.simg"></img>
                        <img  v-else style="width: 14px;height: 14px;margin-right: 15px;" :src="item.nimg"></img>
                        <div class="">{{ item.name }}</div>
                    </div>
                </div>

                <div style="flex:1;">
                    <div class="scrollView" v-loading="loadingStatus" :infinite-scroll-disabled="noMore" v-infinite-scroll="loadData">
                        <div class="contentItem" v-for="(item,index) in listData" :key="index">

                            <div style="display: flex;align-items: center;">
                                <div @click="goDetail({item})" class="personName" style="font-weight: bold; cursor: pointer;">{{item.name}}</div>
                                <i class="el-icon el-icon-female" v-if="item.sex==='女'" style="color: #FF756B;margin-left: 5px;margin-right: 5px;"></i>
                                <i class="el-icon el-icon-male" v-else style="color: #1890FF;margin-left: 5px;margin-right: 5px;"></i>
                                <div>{{item.no}}</div>
                            </div>
                            <div style="color: #888888;margin-top: 10px;font-size: 14px;">注册企业：{{item.company}}</div>

                            <div style="display: flex;margin-top: 10px;">
                                <div class="theme-color qualifiName" style="margin-right: 6px;">{{item.typeName}}</div>
                                <!-- <div class="theme-color qualifiName" style="margin-right: 6px;">
                                    一级注册建筑师</div>
                                <div class="theme-color qualifiName">
                                    ...</div> -->
                            </div>
                        </div>
                        <div v-if="!listData.length" style="display: flex;justify-content: center;flex: 1;">
                            <el-empty  description="暂无数据"></el-empty>
                        
                        </div>
                    </div>
                    <p v-if="loadingStatus">加载中...</p>
                    <p v-if="noMore&&listData.length">没有更多了</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getAreaPerson ,getAppidIsTree} from "@/api/bid.js"

    export default {
        data() {
            return {
                qualificationList: [],
                typeIndex: 0,
                typeName:'',
                loadingStatus: false,
                listData: [],
                total: 0,
                pagesNunber:0,
                currentPage: 1,
                size: 10,
            };
        },
        computed: {
            disabled() {
                return false
            },
            noMore(){
                return  this.size*this.currentPage >=this.pagesNunber?true:false
            },
        },
         async created() {
            await this.getAppidIsTree()
            this.getAreaPerson()
        },
        methods: {
            goDetail({item}){
                this.$router.push({path:'/personnel/index',query:{name:item.name,showingRow:{...item}}})
            },
            //获取人员资质类别
            async getAppidIsTree() {
                try {
                    let { data } = await getAppidIsTree({ appid: 'wxbd94b37dd45c1f91' })
                    let list = data.filter(item => item.label == '人员类别')
                    let allList = []
                    list.forEach((item) => {
                        if (item?.child?.length) {
                            item.child.forEach((citem) => {
                                citem.value = citem.name
                                allList.push(citem)
                            })

                        }
                    })
                    list.forEach((item,index)=>{
                        if(index===0){
                            item.nimg='https://oss.yanxin7.cn/2023-09-07/487bca652fa32aa2d47302c374c0d286'
                            item.simg='https://oss.yanxin7.cn/2023-09-07/a7ba7ce2519ffb7b6ffafd0ba2794f6b'
                        }else if(index===1){
                            item.nimg='https://oss.yanxin7.cn/2023-09-07/80424677604a72526cf59d68b1b7b2e0'
                            item.simg='https://oss.yanxin7.cn/2023-09-07/52a0229bc11e68a8ade9c05726ad9130'
                        }else if(index===2){
                            item.nimg='https://oss.yanxin7.cn/2023-09-07/ac359874ebaa4457380845bd0dbb6c00'
                            item.simg='https://oss.yanxin7.cn/2023-09-07/9254e38a5cbb103c1d81b8fbd29245f2'
                        }else if(index===3){
                            item.nimg='https://oss.yanxin7.cn/2023-09-07/c64815b34b9b436a7f9de9824f843aaf'
                            item.simg='https://oss.yanxin7.cn/2023-09-07/e81ef3dbc586941d09b062c33d3aa964'
                        }else if(index===4){
                            item.nimg='https://oss.yanxin7.cn/2023-09-07/7c186eb93a88db90e0a7f8500645eb80'
                            item.simg='https://oss.yanxin7.cn/2023-09-07/8529ee17c980c8587162a3145c65dc3a'
                        }
                    })
                 
                    this.qualificationList = list
                
                   
                } catch (error) {

                }
            },
            initParams(){
                this.currentPage=1
                this.listData=[]
                this.getAreaPerson()

            },
            async getAreaPerson() {
                try {
                    let params={
                        typeName:this.typeName,
                        size:this.size,
                        current:this.currentPage
                    }
                    this.loadingStatus=true
                    let { data } = await getAreaPerson(params);
                    if(data.pages)
                    this.listData=  this.listData.concat(data.records||[])
                    this.total=data.total
                    this.pagesNunber=data.pages
                    this.loadingStatus=false
                } catch (error) {
                    this.loadingStatus=false
                }
            },
            selectType({ item, index }) {
                this.typeIndex = index
                this.typeName = item.name
                this.initParams()
            },
            loadData() {
                console.log('====11加载')
                this.currentPage++
                this.getAreaPerson()
            },
        }
    };
</script>

<style scoped>
    .theme-color {
        color: #1890ff;
    }
    .personName:hover{
        color: #1890ff;
    }

    .tab-item {
        display: flex;
        align-items: center;
        width: 240px;
        padding-left: 20px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-weight: bold;

    }

    .borderRight {
        border-right: 1px solid #E2E5EC;
    }

    .select-item {
        border-right: 2px solid #1890FF;
        background: #F2F9FF;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding-top: 20px;
        color: #212121;
        margin-top: 20px;
    }

    .contentItem {
        width: 33%;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }

    .qualifiName {
        border: solid 1px #9CD1FF;
        font-size: 14px;
        padding: 4px 6px;

        border-radius: 4px;
    }

    .scrollView {
        display: flex;
        flex-wrap: wrap;
        padding-left: 40px;
        height: 450px;
        overflow: hidden;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto
    }

    .scrollView::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    .scrollView::-webkit-scrollbar-thumb {
        background: #1890FF;
        border-radius: 30px;
    }

    .scrollView::-webkit-scrollbar-track {}
</style>
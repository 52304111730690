<template>
    <div>
        <div class="border-box">
            <div style="display:flex ;justify-content: center;align-items: center;">
                <div style="font-weight: bold;font-size: 24px;">区域排行榜（{{showProvince}}）</div>
                <el-select v-model="province" size="mini" placeholder="请选择省份" @change="selectProvince">
                    <el-option v-for="item in provinceList" :key="item.adcode" :label="item.label" :value="item.label">
                    </el-option>
                </el-select>
            </div>

            <div class="renkingList">
                <div class="borderSoild" style="background: #FCFDFF;">
                    <div style="font-size: 20px;font-weight: bold;">企业信用排行榜</div>
                    <div
                        style="display: flex;justify-content: space-between;color: #888888;font-size: 14px;margin-top: 20px;">
                        <div>企业名称</div>
                        <div>信用分</div>
                    </div>
                    <div class="scrollViewY" v-loading="scoreLoading"  :infinite-scroll-disabled="disabled" v-infinite-scroll="loadScore">
                        <div  style="display: flex;font-size: 14px;margin-top: 20px;cursor: pointer;" @click="goDetail(item)"
                            v-for="(item,index) in scoreList" :key="index">
                            <div style="display: flex;max-width: 210px;margin-right: 10px;">
                                <div style="margin-right: 10px;">{{index+1}}</div>
                                <div  class="hideline1 companyName" style="min-width: 0;width: 180px;text-align: left;">
                                    {{item.entName||item.subjectName}}
                                </div>
                            </div>
                            <div style="color: #035ACB;font-weight: bold;" v-if="item.grade">{{item.grad}}</div>
                            <div style="color: #035ACB;font-weight: bold;" v-else>{{item.score|toFixed2}}</div>
                        </div>
                    </div>
                    <div v-if="noMore1"></div>
                    <div v-else style="color: #888888;font-size: 12px ;padding-top: 10px;">{{scoreLoading?'加载中...':'上滑加载更多'}}</div>

                </div>
                <div class="borderSoild" style="background: #FDFFFC;border: solid 1px #C1FFA2;">
                    <div style="font-size: 20px;font-weight: bold;">企业资质排行榜</div>
                    <div
                        style="display: flex;justify-content: space-between;display: flex;color: #888888;font-size: 14px;margin-top: 20px;">
                        <div>企业名称</div>
                        <div>资质数量</div>
                    </div>
                    <div class="scrollViewY"  v-loading="quaLoading" :infinite-scroll-disabled="disabled" v-infinite-scroll="loadQualifi">
                        <div style="display: flex;font-size: 14px;margin-top: 20px;cursor: pointer;" @click="goDetail(item)"
                            v-for="(item,index) in qualifiList" :key="index">
                            <div style="display: flex;max-width: 210px;margin-right: 10px;">
                                <div style="margin-right: 10px;">{{index+1}}</div>
                                <div class="hideline1 companyName" style="min-width: 0;width: 180px;text-align: left;">
                                    {{item.fullName}}
                                </div>
                            </div>
                            <div style="color: #2CB843;font-weight: bold;text-align: left;">{{item.quaCount}}</div>
                        </div>
                    </div>
                    <div v-if="noMore2"></div>
                    <div v-else style="color: #888888;font-size: 12px ;padding-top: 10px;">{{quaLoading?'加载中...':'上滑加载更多'}}</div>

                </div>
                <div class="borderSoild" style="background: #FFFEFC;border: solid 1px #FEE7B8;">
                    <div style="font-size: 20px;font-weight: bold;">企业中标排行榜</div>
                    <div
                        style="display: flex;justify-content: space-between;color: #888888;font-size: 14px;margin-top: 20px;">
                        <div>企业名称</div>
                        <div>中标数量</div>
                    </div>
                    <div class="scrollViewY" v-loading="projectLoading" :infinite-scroll-disabled="disabled" v-infinite-scroll="projectLoadData">
                        <div   style="display: flex;font-size: 14px;margin-top: 20px;cursor: pointer;" @click="goDetail(item)"
                            v-for="(item,index) in projectList" :key="index">
                            <div style="display: flex;margin-right: 10px;">
                                <div style="margin-right: 10px;">{{index+1}}</div>
                                <div class="hideline1 companyName" style="min-width: 0;width: 180px;text-align: left;">{{item.name}}
                                </div>
                            </div>
                            <div style="color: #D27F12;font-weight: bold;text-align: left;">{{item.projectCount||0}}
                            </div>
                        </div>
                    </div>
                    <div v-if="noMore3"></div>
                    <div v-else style="color: #888888;font-size: 12px ;padding-top: 10px;">{{projectLoading?'加载中...':'上滑加载更多'}}</div>
                </div>

                <div class="borderSoild" style="background: #FFFCFE;border: solid 1px #FFDBF3;">
                    <div style="font-size: 20px;font-weight: bold;">企业业绩排行榜</div>
                    <div
                        style="display: flex;justify-content: space-between;color: #888888;font-size: 14px;margin-top: 20px;">
                        <div>企业名称</div>
                        <div>业绩金额</div>
                    </div>
                    <div class="scrollViewY"  v-loading="priceLoading" :infinite-scroll-disabled="disabled" v-infinite-scroll="priceLoadData">
                        <div style="display: flex;font-size: 14px;margin-top: 20px;cursor: pointer;" @click="goDetail(item)"
                            v-for="(item,index) in priceList" :key="index">
                            <div style="display: flex;max-width: 210px;margin-right: 10px;">
                                <div style="margin-right: 10px;">{{index+1}}</div>
                                <div class="hideline1 companyName" style="min-width: 0;width: 180px;text-align: left;">{{item.name}}
                                </div>
                            </div>
                            <div style="color: #DE1FD1;font-weight: bold;text-align: right;">{{
                                transNumberToShort(item.totalAmount)||0}}元</div>
                        </div>
                    </div>
                    <div v-if="noMore4"></div>
                    <div v-else style="color: #888888;font-size: 12px ;padding-top: 10px;">{{priceLoading?'加载中...':'上滑加载更多'}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from "vuex" 
    import { getscorePage, enQualification, getProjectRanking, getPriceRanking } from "@/api/bid.js"
    export default {
        data() {
            return {

                typeIndex: 0,
                province: '全国',
                disabled: false,
                size: 20,

                scoreLoading:false,
                scoreCurrent: 1,
                scoreList: [],
                qualifiCurrent: 1,
                qualifiList: [],
                pagesNumber1:0,
                quaLoading:false,
                projectCurrent: 1,
                pagesNumber2:0,
                projectList: [],
                projectLoading:false,
                pagesNumber3:0,
                priceList: [],
                priceCurrent: 1,
                pagesNumber4:0,
                priceLoading:false,
            };
        },
        created() {
            this.getScore()
            this.enQualification()
            this.getProjectRanking()
            this.getPriceRanking()
            if (!this.regionList.length) {
                this.$store.dispatch("account/getRegion").then(res => {

                })
            } else {

            }
        },
        filters:{
            toFixed2(num){
                if(!num) return '-'

                return Number(num).toFixed(2)+'分'
            },
        },
        computed: {
            ...mapState("account", ["regionList"]),
            showProvince(){

                return (this.province==='全国'||this.province==='')?'全国':this.province
            },
            provinceList() {
                let list = this.regionList.map((item) => {
                    return {
                        label: item.name,

                        adcode: item.adCode,
                    }
                })

                return [{ label: '全国', value: '' }].concat(list)
            },

            noMore1(){
                return  this.size*this.scoreCurrent >=this.pagesNumber1?true:false
            },
            noMore2(){
                return  this.size*this.qualifiCurrent >=this.pagesNumber2?true:false
            },
            noMore3(){
                return  this.size*this.projectCurrent >=this.pagesNumber3?true:false
            },
            noMore4(){
                return  this.size*this.priceCurrent >=this.pagesNumber4?true:false
            },


        },
        props:{
			provinceRanKing:{
				type:String,
				default:()=>{
					return ''
				}
			},
            provinceAdcode:{
				type:String,
				default:()=>{
					return ''
				}
			},
		},
        watch: { 
            provinceRanKing: {
				handler(newsVal, oldVal) {
                    console.log('===newsVal',newsVal)
                    this.province=!newsVal?'全国':newsVal
					this.selectProvince()
				},
				immediate: true
            }
        },
        mounted() {
          
        },

        methods: {
            selectProvince() {
                this.scoreCurrent= 1
                this.scoreList= []
                this.qualifiCurrent= 1
                this.qualifiList= []
                this.projectCurrent=1
                this.projectList= []
                this.priceList= []
                this.priceCurrent= 1
                this.getScore()
                this.enQualification()
                this.getProjectRanking()
                this.getPriceRanking()
            },
            goDetail(item) {
                console.log('item', item)
                this.$router.push({
                    path: '/qualification/detail',
                    query: { unicode: item.creditCode||item.unicode, },
                });
            },
            transNumberToShort(value, decimal = 2) {
                if(!value) return '0元'
                let newVal=Number(value).toFixed(2)
                const BASE = 10000;
                const SIZES = ["", "万", "亿", "万亿"];
                let i = undefined;
                let str = "";
                if (isNaN(newVal)) {
                    throw new Error("The input parameter is not a number.");
                }
                if (typeof decimal !== "number" || decimal < 0) {
                    throw new Error("The 'decimal' parameter should be a non-negative number.");
                }
                if (newVal < BASE) {
                    str = newVal;
                } else {
                    i = Math.floor(Math.log(newVal) / Math.log(BASE));
                    str = `${((newVal / Math.pow(BASE, i))).toFixed(decimal)}${SIZES[i]}`;
                }
                return str;
            },

            loadScore() {
                if(this.noMore1) return
                this.scoreCurrent = this.scoreCurrent + 1
                this.getScore()

            },
            loadQualifi() {
                if(this.noMore2) return
                this.qualifiCurrent++
                this.enQualification()
            },

            projectLoadData() {
                if(this.noMore3) return
                this.projectCurrent++
                this.getProjectRanking()
            },
            priceLoadData() {
                if(this.noMore4) return
                this.priceCurrent++
                this.getPriceRanking()
            },
            async getScore() {
                try {
                    let params = {
                        current: this.scoreCurrent,
                        size: this.size,
                        fromProvince: this.province==='全国'?'':this.province,
                    }
                    this.scoreLoading=true
                    let { data } = await getscorePage(params)
                     this.pagesNumber1=data.pages
                    this.scoreList = this.scoreList.concat(data.records)
                    this.scoreLoading=false
                } catch (error) {
                    this.scoreLoading=false
                }
            },
            async enQualification() {
                try {
                    let params = {
                        current: this.qualifiCurrent,
                        size: this.size,
                        province: this.province==='全国'?'':this.province,

                    }
                    this.quaLoading=true
                    let { data } = await enQualification(params)
                    this.qualifiList = this.qualifiList.concat(data.records)
                    this.pagesNumber2=data.pages
                    this.quaLoading=false
                } catch (error) {
                    this.quaLoading=false
                }
            },
            async getProjectRanking() {

                try {
                    let params = {
                        current: this.projectCurrent,
                        size: this.size,
                        province: this.province==='全国'?'':this.province,

                    }
                    this.projectLoading=true
                    let { data } = await getProjectRanking(params)
                    this.projectList = this.projectList.concat(data.records)
                    this.pagesNumber3=data.pages
                    this.projectLoading=false
                } catch (error) {
                    this.projectLoading=false
                }
            },
            async getPriceRanking() {

                try {
                    let params = {
                        current: this.priceCurrent,
                        size: this.size,
                        province: this.province==='全国'?'':this.province,

                    }
                    this.priceLoading=true
                    let { data } = await getPriceRanking(params)
                    this.priceList = this.priceList.concat(data.records)
                    this.pagesNumber4=data.pages
                    this.priceLoading=false
                } catch (error) {
                    this.priceLoading=false
                }
            },

            selectType({ item, index }) {
                this.typeIndex = index
            },
            loadData() { },

        }
    };
</script>

<style scoped>
    .companyName:hover{
        color: #1890FF;
    }
    .theme-color {
        color: #1890ff;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding: 20px;
        color: #212121;
        margin-top: 20px;

    }

    .borderSoild {
        border: 1px solid #D1DFFB;
        padding: 20px;
    }

    .renkingList {
        display: flex;
        justify-content: space-around;
        margin-top: 24px;
    }

    .scrollViewY {
        overflow: hidden; 
        overflow: scroll;
        overflow-x: hidden;
        height: 400px;
    }

    .scrollViewY::-webkit-scrollbar {
        width: 0px;

    }

    .scrollViewY::-webkit-scrollbar-thumb {
        background: #1890FF;
        border-radius: 30px;
    }

    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<template>
    <div>
        <div ref="mainMapRef" :style="{width: width + 'px', height: height + 'px'}"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {
    provinceList,
    zhongguo
} from './mapJson'

import {
    browseAllGrade
} from '@/api/enterprise/home'

import {
    mapState
} from "vuex";
export default {
    props: {
        width: {
            type: [Number, String],
            default: 720,
        },
        height: {
            type: [Number, String],
            default: 640,
        },
    },
    data() {
        return {
            geoMapType: 'china', // china:包含南海诸岛 中国：不包含
            provinceList: provinceList,
            mainChart: null,
            allGradeList: [], // 全国地图上的tooltip信息
            mapLevel: '', // 地图显示的层级，空:全国地图；province:省份地图
        }
    },

    mounted() {
        this.setupMap()
        this.fetchData()
    },

    methods: {

        fetchData() {
            let params = {
            }
            let that = this
            browseAllGrade(params).then(res => {
                that.allGradeList = res.data || []
                that.setupMap()
            }) 

        },

        setupMap(region) {
            if (this.mainChart) {
                this.mainChart.clear();
                this.mainChart.off("click");
                this.mainChart.off('dblclick')
            } else {}
            this.mapLevel = region ? 'province' : ''
            // // china:包含南海诸岛 中国：不包含
            this.geoMapType = this.mapLevel ? '中国' : 'china'
            echarts.registerMap(this.geoMapType, region || zhongguo);
            this.initCharts();
        },

        changeProvince(proviceName) {
            if (proviceName) {
                this.setupMap(this.provinceList[proviceName])
            } else {
                this.setupMap()
            }
        },

        initCharts() {
            let myChart = echarts.init(this.$refs["mainMapRef"]);
            this.mainChart = myChart

            // 隐藏省会标记

            // const geoCoordMap = {
            //   '北京': ['116.46', '39.92'],
            //   '上海': ['121.48', '31.22'],
            //   '天津': ['117.2', '39.13'],
            //   '重庆': ['106.54', '29.59'],
            //   '河北': ['114.48', '38.03'],
            //   '山西': ['112.53', '37.87'],
            //   '辽宁': ['123.38', '41.8'],
            //   '吉林': ['125.35', '43.88'],
            //   '黑龙江': ['126.63', '45.75'],
            //   '浙江': ['120.19', '30.26'],
            //   '福建': ['119.3', '26.08'],
            //   '山东': ['106.54', '29.59'],
            //   '河南': ['113.65', '34.76'],
            //   '湖北': ['114.31', '30.52'],
            //   '湖南': ['113', '28.21'],
            //   '广东': ['113.23', '23.16'],
            //   '海南': ['110.35', '20.02'],
            //   '四川': ['104.06', '30.67'],
            //   '贵州': ['106.71', '26.57'],
            //   '云南': ['102.73', '25.04'],
            //   '江西': ['115.89', '28.68'],
            //   '陕西': ['108.95', '34.27'],
            //   '青海': ['101.74', '36.56'],
            //   '甘肃': ['103.73', '36.03'],
            //   '广西': [108.33, 22.84],
            //   '新疆': ['87.68', '43.77'],
            //   '内蒙古': ['111.65', '40.82'],
            //   '西藏': ['91.11', '29.97'],
            //   '宁夏': ['106.27', '38.47'],
            //   '台湾': ['121.5', '25.14'],
            //   '香港': ['114.1', '22.2'],
            //   '澳门': ['113.33', '22.13'],
            //   '安徽': ['117.27', '31.86'],
            //   '江苏': ['118.78', '32.04']
            // }

            // let convertData = Object.keys(geoCoordMap).map(key => {
            //     return {
            //         name: key,
            //         value: geoCoordMap[key].concat(1)
            //     }
            // })

            let that = this
            const option = {
                tooltip: {
                    show: true,
                    trigger: "item",
                    alwaysShowContent: false,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderColor: "rgba(0, 0, 0, 0.16);",
                    triggerOn: "mousemove",
                    enterable: true, //鼠标是否可进入提示框浮层中
                    textStyle: {
                        fontSize: "12",
                        overflow: "break",
                        color: '#fff',
                    },
                    formatter: function (params, ticket, callback) {
                        // console.log('tooltip params:', params)
                        let name = params.name
                        let str = `<div style="min-width: 100px;">`;                        
                        let proviceItem = that.allGradeList.find(province => province.name.includes(name))
                        // console.log('tooltip proviceItem:', proviceItem)
                        if (proviceItem && proviceItem.list && proviceItem.list.length) {
                            str += `<div>${proviceItem.name}</div>`
                            let list = [...proviceItem.list]
                            for (let index = 0; index < list.length; index++) {
                                const item = list[index];
                                let firstKey = Object.keys(item)[0]
                                let value = item[firstKey] || 0
                                str += `<div style="text-align:left;">${firstKey}企业：${value}家</div>`
                            }
                        } else {
                            str += `<div>${name}</div>`
                        }
                        
                        str += `</div>`
                        callback(ticket, str);

                        return str || '加载中';
                    },
                },

                // 地图配置
                geo: {
                    map: this.geoMapType,
                    // aspectScale: 0.75, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
                    zoom: 1.25, //视觉比例大小,1.2即为原有大小的1.2倍
                    scaleLimit: {
                        min: 1.25,
                        max: 1.25,
                    },
                    roam: false, //是否开启鼠标缩放和平移漫游。默认不开启。可以不用设置,如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。
                    top: "15%",
                    label: {
                        show: true,
                        color: "#00407B",
                    },
                    // 高亮状态没有生效？？
                    emphasis: {
                        disabled: false,
                        label: {
                            show: true,
                            color: '#0f0'
                        },
                        itemStyle: {
                            color: "rgba(0, 0, 0, 0.1)",
                        }
                    },
                    // 地图区域的样式设置
                    itemStyle: {
                        areaColor: '#41A6FF',
                        borderColor: '#fff',
                    },
                },

                series: [
                    // {
                    //     type: 'scatter',
                    //     coordinateSystem: 'geo',
                    //     itemStyle: {
                    //       color: '#DC5800'
                    //     },
                    //     data: convertData,
                    //     label: {
                    //       formatter: '{b}',
                    //       position: 'top',
                    //       color: "#00407B",
                    //       show: true
                    //     },
                    // },
                    {
                        type: "map",
                        map: "china", // 引入地图数据
                        geoIndex: 0, //将数据和第0个geo配置关联在一起
                    }, 
                ],
            }

            myChart.setOption(option)
            let _this = this;
            var timeFn = null;
            this.mainChart.on("mouseover", function (e) {
                //   console.log("mouseover", e);
                //取消鼠标移入地图区域高亮
                _this.mainChart.dispatchAction({
                    type: "legendUnSelect",
                });
            });
            myChart.on("click", (e) => {
                console.log('---点击地图', e)
                //鼠标点击取消高亮
                _this.mainChart.dispatchAction({
                    type: "legendUnSelect",
                });

                let targetName = e.name || ''
                let provinceRegion = _this.provinceList[targetName]
                clearTimeout(timeFn);
                timeFn = setTimeout(function () {
                    if (provinceRegion) {
                        _this.setupMap(provinceRegion)
                        _this.$emit('mapDidChange', {mapLevel:_this.mapLevel, targetName})
                        // TODO: 选中某个省
                        // _this.mapCurProvice = e.data.name;
                        _this.mainChart.off("click");
                        console.log("有数据");
                    } else {
                        console.log('该区域没有数据')
                    }
                }, 250);
            });

            myChart.on("dblclick", function () {
                if (that.mapLevel === 'province') {
                    that.setupMap()
                    that.$emit('mapDidChange', {mapLevel:_this.mapLevel})
                }
            });
        }
    },

}
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="border-box">

            <div style="margin-top: 60px;margin-bottom: 30px; font-size: 24px;font-weight: bold;">企业服务</div>



            <div style="display: flex;justify-content: space-around;">
                <div style="display: flex;flex-direction: column;align-items: center;"
                    v-for="(item,index) in serviceType" :key="index">
                    <el-image class="bannerImg"
                        :src="item.url"></el-image>
                    <div style="font-size: 24px;font-weight: bold;margin-top: 20px;">{{item.title}}</div>
                    <div style="font-size: 14px;margin-top: 20px;margin-bottom: 20px;color: #888888;width: 300px;">
                        {{item.descrition}}</div>
                    <div class="goConsult">
                        去咨询</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                serviceType: [
                    {
                        title: 'ChatGPT', 
                        descrition: '配置一对一专业智能服务，为您提供专业咨询，赋能建筑企业，创新转型升级',
                        url:'https://oss.yanxin7.cn/2023-09-04/d8c0379c335c25be622dbd99f890ef1b',
                    }, {
                        title: '大数据分析',
                         descrition: '大数据融合，感知数据，打通孤岛，精准匹配客户需求，提高中标概率',
                         url:'https://oss.yanxin7.cn/2023-09-04/34fb4bf068bdc9a79efad0bab2c73fd5',
                    }, {
                        title: '建筑行业大数据湖',
                         descrition: '打破数据信息壁垒，快速全面掌握行业趋势，开放数据服务，拓展企业数据增值场景',
                         url:'https://oss.yanxin7.cn/2023-09-04/f4267d852f38c9f7fdd96d546a0bd983',
                    }],
                typeIndex: 0,
            };
        },
        methods: {
            selectType({ item, index }) {
                this.typeIndex = index
            },
        }
    };
</script>

<style scoped>
    .theme-color {
        color: #1890ff;
    }

    .border-box {

        font-size: 16px;
        padding: 20px;
        color: #212121;
        margin-top: 20px;

    }

    .goConsult {
        background: #1890FF;
        color: #FFFFFF;
        font-size: 14px;
        display: inline-block;
        border-radius: 20px;
        padding: 6px 20px;
        cursor: pointer;
    }

    .bannerImg {
        width: 400px;
        height: 300px;
    }
</style>
<template>
    <div>
        <div ref="chartRef" :style="{ width: width + 'px', height: height + 'px' }"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {
  browseESEnType
} from '@/api/enterprise/home'
export default {
    props: {
        width: {
            type: [Number, String],
            default: 380,
        },
        height: {
            type: [Number, String],
            default: 380,
        },
    },

    data() {
        return {
            mainChart: null,
            sourceList: [],
        }
    },

    mounted() {
        this.setupChart()
    },

    methods: {
        fetchData(province, year) {
          let params = {
              province: province,
              year: year,
          }
          let that = this
          that.mainChart?.showLoading('default', {text: '加载中'})
          browseESEnType(params).then(res => {
            let data = res.data || []
            let obj = data[0] || {}
            that.sourceList = Object.keys(obj).map(key => {
                let name = key.split('_').pop()
                return {
                    name: name,
                    value: obj[key] || 0
                }
            })
            that.setupChart()

          }).finally(() => {
              that.mainChart?.hideLoading()
          })
          
        },
        setupChart() {
            let myChart = echarts.init(this.$refs["chartRef"]);
            if (!myChart) {
                return
            }
            this.mainChart = myChart
            let option = {
                color: ['#6F78FB', '#41A6FF', '#67E9A0', '#FFDD00', '#FFAA2A', '#FF756B', '#E06FFB'],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '1%',
                    left: 'center'
                },
                grid: {
                    top: 100,
                },
                series: [{
                    name: '',
                    type: 'pie',
                    radius: ['20%', '40%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        formatter(param) {
                            // return param.name + ' ' + param.value + ' (' + param.percent + '%)';
                            return param.name
                        }
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    labelLine: {
                        show: true
                    },
                    data: [...this.sourceList]
                }]
            };

            myChart.setOption(option, true)
        }
    },

}
</script>

<style  scoped></style>

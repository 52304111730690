<template>
    <div class="bg-box a-flex-row">
        <div class="content-box" :style="{width: limitWidth + 'px'}">
            <div>产品动态：</div>
            <div style="margin-left: 40px;">|</div>
            <div class="" style="flex-grow: 1;text-align: left;">
                <el-carousel :height="limitHeight + 'px'" direction="vertical">
                    <el-carousel-item v-for="(item,index) in leftList" :key="index">
                        <div class="a-flex-row title" :style="{height: limitHeight + 'px',}" style="cursor: pointer;"
                            @click="clickItem(item)">
                            <div class="item-point"></div>
                            <div style="max-width: 400px;" class="hideline1">{{ item.informationTitle }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="" style="flex-grow: 1;">
                <el-carousel :height="limitHeight + 'px'" direction="vertical">
                    <el-carousel-item v-for="(item,index) in rightList" :key="index">
                        <div class="a-flex-row title" :style="{height: limitHeight + 'px',}" style="cursor: pointer;"@click="clickItem(item)">
                            <div class="item-point"></div>
                            <div style="max-width: 400px;" class="hideline1">{{ item.informationTitle }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div style="color: #888888;cursor: pointer;" @click="clickMore">
                更多>>
            </div>
        </div>

    </div>
</template>

<script>
    import { industryInformation } from "@/api/bid.js"
    export default {
        props: {
            // 内容区宽度
            limitWidth: {
                type: [Number, String],
                default: 1000,
            },
            limitHeight: {
                type: [Number, String],
                default: 50,
            }
        },
        data() {
            return {
                dataList: [],
            }
        },
        computed: {
            leftList() {
                return this.dataList.slice(0, 10)
            },

            rightList() {
                if (this.dataList.length < 10) {
                    return this.dataList.slice(5, 10)
                } else {
                    return this.dataList.slice(10, 20)
                }

            },

        },
        created() {
            this.getListData()
        },
        mounted() {
        },
        methods: {

            goDetail({ item }) {
                this.$router.push({
                    path: '/enterprise/newsDetail',
                    query: { informationId: item.informationId, fromPage: 'sercher' },
                });
            },

            async getListData() {
                try {
                    let params = {
                        size: 20,
                        current: 1,
                        infoType: 'productDynamics',
                    }
                    let { data } = await industryInformation(params)
                    this.dataList = data.records
                } catch (error) {
                    console.log('动态error', error)
                }
            },
            clickItem(item) {
                this.$router.push({
                    path: '/enterprise/dynamicDetail',
                    query: {informationId: item.informationId,},
                });
            },

            clickMore() {
                this.$router.push({
                    path: '/enterprise/dynamicList',
                    query: {},
                });
            }
        },

    }
</script>

<style scoped>
    .title:hover{
        color: #1890FF!important;
    }
   
    .bg-box {
        background-color: #FFFFFF;
        width: 100%;
        box-shadow: 0px 3px 6px 1px rgba(3, 96, 230, 0.06);
    }

    .content-box {
        color: #212121;
        font-weight: 400;
        min-height: 50px;
        display: flex;
        align-items: center;
    }

    .a-flex-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item-point {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #212121;
        opacity: 1;
        margin-right: 10px;
        flex-shrink: 0;
    }
    .item-point:hover{
        color: #1890FF;
    }

    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
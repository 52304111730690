<template>
    <div>
        <div class="border-box">
            <div style="font-size: 16px;display:flex ;justify-content: space-between;">
                <div style="font-weight: bold;">今日中标企业（{{province?province:'全国'}}）</div>
                <div style="font-size: 14px;color: #888888;cursor: pointer;" @click="more()">更多>></div>
            </div>

            <div style="display: flex;margin-top: 20px;">
                <div class="" style="display: flex;width: 420px;flex-wrap: wrap;">
                    <el-image class="bannerImg" v-for="(item,index) in adList" :key="index"
                        :src="item.logo"></el-image>

                </div>

                <div style="flex:1;margin-left: 40px;">
                    <div style="color: #888888;font-size: 14px;margin-bottom: 20px;text-align: left;">中标日期：{{timeStr}}
                    </div>

                    <div class="project" v-for="(item,index) in listData" :key="index" @click="goDetail({item})"
                        style="background: #FBFBFB;margin-bottom: 10px;padding: 12px 20px;cursor: pointer;">
                        <div style="font-size: 16px;font-weight: bold;text-align: left;">{{item.winningBidder||'-'}}
                        </div>
                        <div style="margin-top: 10px;display: flex;color: #888888;font-size: 14px;">
                            <div class="hideline1" style="width: 500px;text-align: left;">中标项目：{{item.projectName||'-'}}
                            </div>
                            <div style="margin-left: 60px;flex-shrink: 0;">业主单位：{{item.biddingUnit||'-'}}</div>
                            <div style="margin-left: 60px;flex-shrink: 0;">项目金额：{{item.winningAmount||'-'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getBidList,getCatory } from "@/api/bid.js"
    export default {
        data() {
            return {
                current: 1,
                size: 4,
                typeIndex: 0,
                timeStr: '',
                listData: [],
                adList:[],
            };
        },
        created() {
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            let day = date.getDay() < 10 ? '0' + date.getDate() : date.getDate()
            this.timeStr = year + '年' + month + '月' + day + '日'
            this.getListData()
            this.getCatory()
        },
        props: {
            province: {
                type: String,
                default: () => {
                    return ''
                }
            },
            provinceAdcode: {
                type: [String,Number],
                default: () => {
                    return ''
                }
            },
        },
        watch: {
            province: {
                handler(newsVal, oldVal) {
                    this.getListData()
                },
                immediate: true
            }
        },
        methods: {
            async getCatory() {
                try {
                    const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
                    let list= data.filter((item) => item.type === "todayAdvertising");
                    this.adList = list[0]?.child||[]
                } catch (error) {
                    console.log("ee", error);
                }
            },
            more() {
                let params = {};
                if (this.provinceAdcode)
                    params.province = this.provinceAdcode;
                this.$router.push({
                    path: "/home/detail/",
                    query: params,
                });
            },
            goDetail({ item }) {
                this.$router.push({
                    path: "/qualification/detail/",
                    query: { unicode: item.winningBidderCode },
                });
            },
            async getListData() {
                try {
                    let params = {

                        current: this.current,
                        size: this.size,
                        province: this.province === '全国' ? '' : this.province,

                    }
                    let { data } = await getBidList(params)
                    this.listData = data.records
                    console.log('今日中标', this.listData)
                } catch (error) {
                    console.log('===error', error)
                }
            },
            selectType({ item, index }) {
                this.typeIndex = index
            },
        }
    };
</script>

<style scoped>
    .theme-color {
        color: #1890ff;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        color: #212121;
        margin-top: 20px;

    }

    .project:hover {
        color: #1890ff;
        background: #EFF7FF !important;
    }

    .bannerImg {
        width: 200px;
        /* height: 150px; */

        margin-bottom: 20px;
    }

    .bannerImg:nth-of-type(2n + 1) {
        margin-right: 20px;
    }

    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<template>
    <div>
        <div class="border-box">
            
            <div style="margin-top: 60px;font-size: 24px;font-weight: bold;">优质认证企业展示</div>

            <div style="color: #1890FF;font-size: 14px;margin-top: 20px;margin-bottom: 30px; cursor: pointer;" @click="goEnter">去认证企业></div>

            <div style="display: flex;flex-wrap: wrap;">
                <div class="companyItem" style="cursor: pointer;" v-for="(item,index) in companysList" @click="goDetail(item)" :key="index">
                    <div style="font-size: 18px;font-weight: bold; cursor: pointer;" >{{item.name}}</div>
                    <div style="font-size: 14px;color: #888888;margin-top: 20px;">信用代码：{{item.creditCode}}</div>
                    <div style="font-size: 14px;color: #888888;margin-top: 10px;">{{ transNumberToShort(item.totalAmount)}}元</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getPriceRanking} from "@/api/bid.js"
    export default {
        data() {
            return {
                companysList:[],
                typeIndex: 0,
            };
        },
        created(){
            this.getsettlePage()
        },
        methods: {
            goEnter(){
                this.$router.push({
                    path: '/enterCompany/enterCompany',
                    query: { },
                });
            },
            goDetail(item) {
                console.log('item', item)
                this.$router.push({
                    path: '/qualification/detail',
                    query: { unicode: item.creditCode, },
                });
            },
            transNumberToShort(value, decimal = 2){
                const BASE = 10000;
                const SIZES = ["", "万", "亿", "万亿"];
                let i = undefined;
                let str = "";
                if (isNaN(value)) {
                    throw new Error("The input parameter is not a number.");
                }
                if (typeof decimal !== "number" || decimal < 0) {
                    throw new Error("The 'decimal' parameter should be a non-negative number.");
                }
                if (value < BASE) {
                    str = value;
                } else {
                    i = Math.floor(Math.log(value) / Math.log(BASE));
                    str = `${((value / Math.pow(BASE, i))).toFixed(decimal)}${SIZES[i]}`;
                }
                return str;
            },
            //实际上是获取入驻企业
            async getsettlePage(){
                try {
                    let {data} = await getPriceRanking({current:1,size:6})
                    this.companysList=data.records
                } catch (error) {
                    
                }
            },
            selectType({ item, index }) {
                this.typeIndex = index
            },
        }
    };
</script>

<style scoped>
    .theme-color {
        color: #1890ff;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding: 20px;
        color: #212121;
        margin-top: 20px;

    }
    .companyItem{
        border: 1px solid #E2E5EC;
        padding: 20px;
        width: calc((100% - 36px) / 3);
        margin-bottom: 20px;
    }
    .companyItem:nth-of-type(3n + 2){
        margin-left: 16px;
        margin-right: 16px;
    }
    .companyItem:hover{
        box-shadow: 3px 3px 8px 1px #E2E5EC;
        color: #1890FF;
    }
  

</style>
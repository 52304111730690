<template>
    <div>
        <div style="display: flex;">
            <div class="border-box" style="min-height: 800px;padding: 20px;">
                <div class="section-title">企业资质（{{ displayRegionName }}）</div>
                <EnterpriseLevelChart ref="enterpriseLevelChartRef" :width="cornersChartWidth"></EnterpriseLevelChart>
                <div style="width: 100%;height: 1px;margin: 20px 0;background-color: #E2E5EC;"> </div>
                <EnterpriseTypeChart ref="enterpriseTypeChartRef" :width="cornersChartWidth"></EnterpriseTypeChart>
            </div>
            <div style="margin: 0 20px;" :style="{width: chinaMapWidth + 'px'}">
                <div style="display: flex;">
                    <div>
                        <div style="margin-bottom: 20px;">
                            <el-select
                                clearable
                                size="small"
                                @change="handleChangeYear"
                                v-model="selectedYear"
                                placeholder="选择年度">
                                <el-option v-for="item in yearList" :key="item" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div style="">
                            <el-select
                                clearable
                                filterable
                                size="small"
                                @clear="cleanProvince"
                                @change="handleChangeProvince"
                                v-model="selectedProviceName"
                                placeholder="选择省份">
                                <el-option v-for="item in provinceList" :key="item.label" :label="item.label" :value="item.label">
                                </el-option>
                            </el-select>
                        </div>

                    </div>

                    <!-- <div style="margin-left: 30px;display: flex;flex-direction: column;align-items: center;color: #212121;">
                        <div style="font-size: 30px;font-weight: bold;">{{ '115208112 亿' }}</div>
                        <div style="font-size: 16px;font-weight: 400;">{{ selectedYear + '年累计招标交易额' + '(' + displayRegionName + ')' }}</div>
                    </div> -->
                    
                </div>

                <div style="margin-top: 10px;">
                    <MapChart ref="mapChartRef" :height="670" @mapDidChange="mapDidChange"></MapChart>
                </div>

                <!-- <div style="display: flex;text-align: left;margin-top: 20px;">
                    <div class="info-item">
                        <img :src="biddingInfo.icon" style="width: 40px;height: 40px;">
                        <div style="margin-left: 10px;">
                            <div>招标项目（{{ displayRegionName }}）</div>
                            <div style="margin-top: 10px;font-size: 14px;color: #888888;">
                                <span>数量（个）{{ biddingInfo.count }}</span>
                                <span>金额（亿）{{ biddingInfo.money }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-item" style="margin-left: 20px;">
                        <img :src="bidWinInfo.icon" style="width: 40px;height: 40px;">
                        <div style="margin-left: 10px;">
                            <div style="font-size: 16px;font-weight: 500;">招标项目（{{ displayRegionName }}）</div>
                            <div style="margin-top: 10px;font-size: 14px;color: #888888;">
                                <span>数量（个）{{ bidWinInfo.count }}</span>
                                <span>金额（亿）{{ bidWinInfo.money }}</span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="border-box" style="min-height: 800px;padding: 20px;">
                <div class="section-title">招投标项目（{{ displayRegionName }}）</div>
                <ProjectTypeChart ref="projectTypeChartRef" width="340"></ProjectTypeChart>
                <div style="width: 100%;height: 1px;margin: 20px 0;background-color: #E2E5EC;"> </div>
                <PerformanceChart ref="performanceChartRef" width="340"></PerformanceChart>
            </div>
        </div>

        <div style="display: flex;text-align: left;margin-top: 20px;">
            <div class="info-item" style="flex-grow: 0;width: 380px;">
                <div style="margin-left: 10px;">
                    <div>注册企业（{{ displayRegionName }}）</div>
                    <div style="margin-top: 10px;font-size: 14px;color: #888888;">
                        <span style="margin-right: 20px;" v-for="(item, index) in registerEnterpriseList" :key="index">
                           {{ item.name }}：{{ item.value }}家
                        </span>
                    </div>
                </div>
            </div>
            <div class="info-item" style="flex-grow: 1;margin-left: 20px;">
                <div style="margin-left: 10px;">
                    <div>注册人员（{{ displayRegionName }}）</div>
                    <div style="margin-top: 10px;font-size: 14px;color: #888888;">
                        <span style="margin-right: 20px;" v-for="(item, index) in registerPersonList" :key="index">
                           {{ item.name }}：{{ item.value }}人
                        </span>
                    </div>
                </div>
            </div>
        </div>
        

    </div>
</template>

<script>
import MapChart from './mapChart/mapChart.vue';
import EnterpriseLevelChart from './enterpriseLevelChart/enterpriseLevelChart.vue';
import EnterpriseTypeChart from './enterpriseTypeChart/enterpriseTypeChart.vue';
import ProjectTypeChart from './projectTypeChart/projectTypeChart.vue'
import PerformanceChart from './performanceChart/performanceChart.vue'
import {
    mapState
} from "vuex";

import {
    browseRegisterEn,
    browseRegisterPerson
} from '@/api/enterprise/home'

export default {
    components: {
        MapChart,
        EnterpriseLevelChart,
        EnterpriseTypeChart,
        PerformanceChart,
        ProjectTypeChart,
    },

    data() {
        return {
            selectedProviceName: '',
            selectedYear: '',

            // biddingInfo: {
            //     count: 1000,
            //     money: 10000,
            //     icon: require('@/assets/images/enterprise/icons/icon_zhaobiao.png'),
            // },
            // bidWinInfo: {
            //     count: 1000,
            //     money: 10000,
            //     icon: require('@/assets/images/enterprise/icons/icon_zhongbiao.png'),
            // },

            registerEnterpriseList: [],
            registerPersonList: [],

            // 一些常量
            cornersChartWidth: 340,
            chinaMapWidth: 720,
        }
    },

    computed: {
        ...mapState({
            twoAddress: (state) => state.address.twoAddress,
        }),

        provinceList() {
            let list = [...this.twoAddress]
            list.unshift({
                value: 100000,
                label: '全国',
            })
            return list
        },

        yearList() {
            let year = this.getThisYear()
            let years = []
            for (let index = year; index > year - 5; index--) {
                years.push(index)
            }
            return years
        },

        displayRegionName() {
            return this.selectedProviceName
        },
    },

    mounted () {
        this.selectedYear = this.getThisYear();
        this.selectedProviceName = this.provinceList[0]?.label || ''
        let that = this
        this.$nextTick(() => {
            that.reloadAllData()
        })        
    },

    methods: {
        

        reloadAllData() {
            let refs = ['enterpriseLevelChartRef', 'enterpriseTypeChartRef', 'projectTypeChartRef', 'performanceChartRef']
            let province = this.selectedProviceName
            if (province == '全国') {
                province = ''
            }
            for (let index = 0; index < refs.length; index++) {
                const refKey = refs[index];
                this.$refs[refKey] && this.$refs[refKey].fetchData(province, this.selectedYear)
            }
            this.fetchRegisters(province, this.selectedYear)
        },

        fetchRegisters(province, year) {
            let params = {
                province: province,
                year: year,
            }
            let that = this
            that.registerEnterpriseList = []
            browseRegisterEn(params).then(res => {
                let data = res.data || {}
                that.registerEnterpriseList = Object.keys(data).map(key => {
                    return {
                        name: key,
                        value: data[key] || 0
                    }
                })
            })

            that.registerPersonList = []
            browseRegisterPerson(params).then(res => {
                let data = res.data || {}
                that.registerPersonList = Object.keys(data).map(key => {
                    return {
                        name: key,
                        value: data[key] || 0
                    }
                })
            })

        },

        getThisYear() {
            let date = new Date()
            return date.getFullYear()
        },
        cleanProvince() {
            console.log('---cleanProvince')
            this.selectedProviceName = this.provinceList[0]?.label || ''
            this.setMapTo(null)
            this.loadDataWithRegion('china', null)
            this.reloadAllData()
        },
        handleChangeProvince(e) {
            console.log('---handleChangeProvince', e)
            if (!e) {
                return
            }
            
            if (e == '全国') {
                this.setMapTo(null)
                this.loadDataWithRegion('china', null)
            } else {
                let targetProvince = this.provinceList.find(item => e===item.label)

                if (targetProvince) {
                    this.setMapTo(targetProvince.label)
                    this.loadDataWithRegion('provice', targetProvince)
                }
            }
            this.reloadAllData()
        },
        handleChangeYear(e) {
            this.reloadAllData()
            this.$emit('yearChange', this.selectedYear)
        },

        setMapTo(province) {
            let that = this
            this.$nextTick(() => {
                if (province) {
                    that.$refs.mapChartRef.changeProvince(that.getProvinceShortName(province))
                } else {
                    that.$refs.mapChartRef.changeProvince()
                }
                
            })
            
        },

        mapDidChange({mapLevel, targetName}) {
            console.log('---mapDidChange', mapLevel, targetName)
            if (mapLevel == 'province' && targetName) {
                let targetProvice = this.provinceList.find(item => {
                    let short = this.getProvinceShortName(item.label)
                    return short == targetName
                })
                if (targetProvice) {
                    this.selectedProviceName = targetProvice.label
                    this.loadDataWithRegion('province', targetProvice)
                } else {
                    this.loadDataWithRegion('china', null)
                }
                
            } else {
                this.selectedProviceName = this.provinceList[0]?.label || ''
                this.loadDataWithRegion('china', null)
            }
            this.reloadAllData() 
        },

        getProvinceShortName(name) {
            if (name) {
                return name.replace('自治区', '').replace('壮族', '').replace('回族', '').replace('维吾尔', '').replace('省', '').replace('市', '').replace('特别行政区', '')
            } else {
                return name
            }
        },

        loadDataWithRegion(type = '', target) {
            let obj = {
                levelType: type, // 登记类型 china:全国，province：省级，city：市级
                name: '全国', // 名称,例如 全国、广西、南宁
                adcode: 0, // 行政编码
            }
            if (target) {
                obj = {
                    ...obj,
                    ...target,
                    adcode: target.value,
                    name: target.label,
                }
            }
            // console.log('----切换地区', obj)
            this.$emit('regionChange', obj)
        },
    },

}
</script>

<style scoped>
.border-box {
    border: 1px solid #E2E5EC;
}

.section-title {
    font-size: 16px;
    font-weight: 500;
}

.info-item {
    padding: 20px;
    border: 1px solid #E2E5EC;
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
}

</style>

<template>
    <div>
        <div class="border-box">
            <div style="font-size: 16px;display:flex ;justify-content: space-between;">
                <div style="font-weight: bold;">中标项目经理（{{province?province:'全国'}}）</div>
                <div style="font-size: 14px;color: #888888;cursor: pointer;" @click="more">更多>></div>
            </div>

            <div class="scrollViewX">
                <div @click="goDetail({item})" class="maangerBox" v-for="(item,index) in listData " :key="index">
                    <div style="font-weight: bold;font-size: 18px;text-align: left;">
                        <el-image style="width: 16px;height: 16px;margin-right: 10px;"
                            src="https://oss.yanxin7.cn/2023-08-31/e8e25ec8c500fc8216ab3604accff798"></el-image>
                        {{item.winningLeader||'-'}}
                    </div>
                    <div style="margin-top: 20px;font-size: 14px;text-align: left;">
                        <div class="gary" style="margin-top: 10px;"> 所属企业：{{item.winningBidder||'-'}}</div>
                        <div class="gary hideline1" style="margin-top: 10px;"> 中标项目：{{item.projectName}}</div>
                        <div class="gary" style="margin-top: 10px;"> 中标日期：{{item.winningDate||'-'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getBidList } from "@/api/bid.js"
    export default {
        data() {
            return {
                listData: [],
                typeIndex: 0,
                current: 1,
                size: 20,

            };
        },
        created() {

            this.getListData()
        },
        props:{
			province:{
				type:String,
				default:()=>{
					return ''
				}
			},
            provinceAdcode:{
				type:String,
				default:()=>{
					return ''
				}
			},
		},
        watch: {
            province: {
				handler(newsVal, oldVal) {
					this.getListData()
				},
				immediate: true
            }
        },
        methods: {
            more(){
                let params = {};
                if (this.province)
                    params.province = this.province;
                this.$router.push({
                    path: "/home/detailManage/",
                    query: params,
                });
            },
            goDetail({ item }) {

                
                this.$router.push({
                    path: "/personnel/index/",
                    query: { personCode: item.winningLeaderCode },
                });
            },
            async getListData() {
                try {
                    let params = {

                        current: this.current,
                        size: this.size,
                        beWinningLeader: 1,
                        province: this.province==='全国'?'':this.province,
                    }
                    let { data } = await getBidList(params)
                    this.listData = data.records
                } catch (error) {

                }
            },
            selectType({ item, index }) {
                this.typeIndex = index
            },
        }
    };
</script>

<style scoped>
    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .theme-color {
        color: #1890ff;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding: 20px;
        color: #212121;
        margin-top: 20px;

    }

    .scrollViewX {
        overflow-x: scroll;
        white-space: nowrap;
        margin-top: 20px;
    }

    .scrollViewX::-webkit-scrollbar {
        height: 8px;
    }

    .scrollViewX::-webkit-scrollbar-thumb {
        background: #1890FF;
        border-radius: 30px;
    }

    .maangerBox {
        display: inline-block;
        background: #FBFBFB;
        /* border: solid 1px red; */
        border-radius: 8px;
        padding: 20px;
        margin-right: 20px;
        cursor: pointer;
        width: 400px;

    }

    .maangerBox:hover {
        color: #1890FF !important;
        background: #FFFFFF;
        box-shadow: 6px 6px 16px 1px #E2E5EC;
    }

    .gary {
        color: #888888
    }

    .maangerBox:hover .gary {
        color: #1890FF !important;

    }
</style>
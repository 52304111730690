import china from "@/assets/json/china.json"; //中国

import guangxi from "@/assets/json/province/guangxi.json"; //广西
import fujian from "@/assets/json/province/fujian.json"; //福建
import anhui from "@/assets/json/province/anhui.json"; //安徽
import beijing from "@/assets/json/province/beijing.json"; //北京
import chongqing from "@/assets/json/province/chongqing.json"; //重庆
import gansu from "@/assets/json/province/gansu.json"; //甘肃
import guangdong from "@/assets/json/province/guangdong.json"; //广东
import guizhou from "@/assets/json/province/guizhou.json"; //贵州
import hainan from "@/assets/json/province/hainan.json"; //海南
import hebei from "@/assets/json/province/hebei.json"; //河北
import heilongjiang from "@/assets/json/province/heilongjiang.json"; //黑龙江
import henan from "@/assets/json/province/henan.json"; //河南
import hubei from "@/assets/json/province/hubei.json"; //湖北
import hunan from "@/assets/json/province/hunan.json"; //湖南
import jiangsu from "@/assets/json/province/jiangsu.json"; //江苏
import jiangxi from "@/assets/json/province/jiangxi.json"; //江西
import jilin from "@/assets/json/province/jilin.json"; //吉林
import liaoning from "@/assets/json/province/liaoning.json"; //辽宁
import neimenggu from "@/assets/json/province/neimenggu.json"; //内蒙古
import ningxia from "@/assets/json/province/ningxia.json"; //宁夏
import qinghai from "@/assets/json/province/qinghai.json"; //青海
import shandong from "@/assets/json/province/shandong.json"; //山东
import shanghai from "@/assets/json/province/shanghai.json"; //上海
import shanxi from "@/assets/json/province/shanxi.json"; //山西
import shanxi1 from "@/assets/json/province/shanxi1.json"; //陕西
import taiwan from "@/assets/json/province/taiwan.json"; //台湾
import tianjin from "@/assets/json/province/tianjin.json"; //天津
import xizang from "@/assets/json/province/xizang.json"; //西藏
import yunnan from "@/assets/json/province/yunnan.json"; //云南
import sichuan from "@/assets/json/province/sichuan.json"; //四川
import zhejiang from "@/assets/json/province/zhejiang.json"; //浙江
import aomen from "@/assets/json/province/aomen.json"; //澳门
import xianggang from "@/assets/json/province/xianggang.json"; //香港
import xinjiang from "@/assets/json/province/xinjiang.json"; // 新疆



export const provinceList = {
    福建: fujian,
    广西: guangxi,
    新疆: xinjiang,
    安徽: anhui,
    北京: beijing,
    重庆: chongqing,
    甘肃: gansu,
    广东: guangdong,
    贵州: guizhou,
    海南: hainan,
    河北: hebei,
    黑龙江: heilongjiang,
    河南: henan,
    湖北: hubei,
    湖南: hunan,
    江苏: jiangsu,
    江西: jiangxi,
    吉林: jilin,
    辽宁: liaoning,
    内蒙古: neimenggu,
    宁夏: ningxia,
    青海: qinghai,
    山东: shandong,
    上海: shanghai,
    陕西: shanxi1,
    山西: shanxi,
    台湾: taiwan,
    天津: tianjin,
    西藏: xizang,
    云南: yunnan,
    四川: sichuan,
    浙江: zhejiang,
    澳门: aomen,
    香港: xianggang,
}

export const zhongguo = china;
<template>
    <div>
        <div ref="chartRef" :style="{ width: width + 'px', height: height + 'px' }"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {
  browsePerformance
} from '@/api/enterprise/home'
export default {
    props: {
        width: {
            type: [Number, String],
            default: 380,
        },
        height: {
            type: [Number, String],
            default: 380,
        },
    },

    data() {
        return {
            mainChart: null,
            sourceList: [],
        }
    },

    mounted() {
        this.setupChart()
    },

    methods: {
        fetchData(province, year) {
          let params = {
              province: province,
              year: year,
          }
          let that = this
          that.mainChart?.showLoading('default', {text: '加载中'})
          browsePerformance(params).then(res => {
            that.sourceList = res.data || []
            that.setupChart()
          }).finally(() => {
              that.mainChart?.hideLoading()
          })
        },
        setupChart() {
            let myChart = echarts.init(this.$refs["chartRef"]);
            if (!myChart) {
                return
            }
            this.mainChart = myChart

            let months = []
            let series = []
            for (let index = 0; index < this.sourceList.length; index++) {
                const element = this.sourceList[index];
                let sourceKeys = Object.keys(element.map)
                let values = []
                sourceKeys.sort().forEach((key, i) => {
                    if (0 === index) {
                        let month = (key.split('-')[1] || '')
                        months.push(month + '月')
                    }
                    values.push(element.map[key] || 0)

                });
                series.push({
                    name: element.name,
                    type: 'line',
                    stack: 'Total',
                    data: values
                }) 
            }

            let option = {
                color: ['#6F78FB', '#41A6FF', '#67E9A0', '#FFDD00', '#FFAA2A', '#FF756B', '#E06FFB'],
                title: {
                    show: false
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    top: 20,
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    // boundaryGap: false,
                    data: months,
                },
                yAxis: {
                    type: 'value'
                },
                series: series,
            };

            myChart.setOption(option, true)
        }
    },

}
</script>

<style  scoped></style>

<template>
    <div>
        <div ref="chartRef" :style="{ width: width + 'px', height: height + 'px' }"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {
    browseGrade
} from '@/api/enterprise/home'
export default {
    props: {
        width: {
            type: [Number, String],
            default: 380,
        },
        height: {
            type: [Number, String],
            default: 380,
        },
    },

    data() {
        return {
            mainChart: null,
            sourceList: [],
        }
    },

    computed: {
    },

    mounted() {
        this.setupChart()
    },

    methods: {
        fetchData(province, year) {

            let params = {
                province: province,
                year: year,
            }
            let that = this
            console.log('-----获取等级之前', params)
            that.mainChart?.showLoading('default', {text: '加载中'})
            browseGrade(params).then(res => {
                let list = res.data || []
                console.log('-----获取等级之后', list)
                that.sourceList = list
                that.setupChart()
            }).finally(() => {
                that.mainChart?.hideLoading()
            })

        },

        setupChart() {
            let myChart = echarts.init(this.$refs["chartRef"]);
            if (!myChart) {
                return
            }
            this.mainChart = myChart

            // list.map(item => {
            //     let firstKey = Object.keys(item)[0]
            //     let name = firstKey.split('_').pop()
            //     return {
            //         name: name,
            //         value: item[firstKey] || 0
            //     }
            // })
            let yNames = []
            let series = []
            for (let index = 0; index < this.sourceList.length; index++) {
                const element = this.sourceList[index];
                let list = element.list || []
                let values = []
                list.forEach(item => {
                    let key = Object.keys(item)[0]
                    if (0 === index) {
                        yNames.push(key)
                    }
                    values.push(item[key])
                });
                series.push({
                    name: element.name,
                    type: 'bar',
                    barWidth : 20,//柱图宽度
                    data: values,
                }) 
            }
            console.log('-----series', series)
            
            let option = {
                color: ['#6F78FB', '#41A6FF', '#67E9A0', '#FFDD00', '#FFAA2A', '#FF756B', '#E06FFB'],
                title: {
                    show: false,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    top: 20,
                },
                grid: {
                    left: '3%',
                    right: '8%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    inverse: true,
                    data: yNames
                },
                series: series,
            };

            myChart.setOption(option, true)
        }
    },

}
</script>

<style  scoped></style>

import request from '@src/plugins/request'

// 所有企业等级数据统计
export const browseAllGrade = function (data){
    return request({
        url:'/qyzzpt/browse/enAllGrade',
        method:"POST",
        data,
    })
}

// 企业等级数据统计
export const browseGrade = function (data){
    return request({
        url:'/qyzzpt/browse/enGrade',
        method:"POST",
        data,
    })
}

// 企业资质数量统计-需要proAdCode参数和资质小程序四库查询一样的proAdCode
export const browseTypeCount = function (data){
    return request({
        url:'/qyzzpt/browse/enTypeCount',
        method:"POST",
        data,
    })
}

// 企业类型数据统计
export const browseESEnType = function (data){
    return request({
        url:'/qyzzpt/browse/esEnType',
        method:"POST",
        data,
    })
}

// 业绩统计
export const browsePerformance = function (data){
    return request({
        url:'/qyzzpt/browse/performance',
        method:"POST",
        data,
    })
}

// 注册企业数据
export const browseRegisterEn = function (data){
    return request({
        url:'/qyzzpt/browse/registerEn',
        method:"POST",
        data,
    })
}

// 注册人员数据
export const browseRegisterPerson = function (data){
    return request({
        url:'/qyzzpt/browse/registerPerson',
        method:"POST",
        data,
    })
}

// 招投标数据统计
export const browseZTB = function (data){
    return request({
        url:'/qyzzpt/browse/ztb',
        method:"POST",
        data,
    })
}


<template>

  <div style="">
    <HomeBanner></HomeBanner>
    <HomeNotificationBar :limit-width="1520"></HomeNotificationBar>
    <div style="width: 1520px; margin: 0 auto;">
      <HomeMenu></HomeMenu>
      <div style="margin-top:40px;">
        <HomeTopCharts @regionChange="regionChange"></HomeTopCharts>
      </div>
      <div style="margin-top:20px;">
        <el-carousel direction="vertical" arrow="none" height="140px" indicator-position="none">
          <el-carousel-item v-for="(item, index) in adList" :key="index">
            <img style="width: 100%;height:140px;cursor: pointer;" :src="item.icon" @click="toAdDetail(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>


      <qualificationQuery ref="qualificationQuery" :province="province" :provinceAdcode="provinceAdcode"></qualificationQuery>

      <personQuery ref="personQuery"></personQuery>

      <bidTodayCompany ref="bidTodayCompany" :province="province" :provinceAdcode="provinceAdcode"> </bidTodayCompany>

      <bidTodayManager ref="bidTodayManager" :province="province" :provinceAdcode="provinceAdcode"></bidTodayManager>

      <regionalRanking ref="regionalRanking" :provinceRanKing="province" :provinceAdcode="provinceAdcode"> </regionalRanking>

      <bidTodayNotice ref="bidTodayNotice" :provinceBid="province" :provinceAdcode="provinceAdcode"></bidTodayNotice>

      <excellentCompany ref="excellentCompany"></excellentCompany>

      <serviceCompany ref="serviceCompany"></serviceCompany>

      <buildingInformation ref="buildingInformation"></buildingInformation>
    </div>
  </div>
</template>

<script>
import HomeBanner from '../homeComponents/homeBanner/index.vue'
import HomeMenu from '../homeComponents/homeMenu/index.vue'
import HomeNotificationBar from '../homeComponents/homeNotificationBar/index.vue'
import HomeTopCharts from './chartViews/homeTopCharts.vue';
  import qualificationQuery from "../homeComponents/qualificationQuery/index.vue";
  import personQuery from "../homeComponents/personQuery/index.vue";
  import bidTodayCompany from "../homeComponents/bidTodayCompany/index.vue";
  import bidTodayManager from "../homeComponents/bidTodayManager/index.vue";
  import regionalRanking from "../homeComponents/regionalRanking/index.vue";
  import bidTodayNotice from "../homeComponents/bidTodayNotice/index.vue";
  import excellentCompany from "../homeComponents/excellentCompany/index.vue";
  import serviceCompany from "../homeComponents/serviceCompany/index.vue";
  import buildingInformation from "../homeComponents/buildingInformation/index.vue";
  export default {
    components: {
      HomeBanner,
      HomeMenu,
      HomeTopCharts,
      HomeNotificationBar,
      qualificationQuery,
      personQuery,
      bidTodayCompany,
      bidTodayManager,
      regionalRanking,
      bidTodayNotice,
      excellentCompany,
      serviceCompany,
      buildingInformation,

    },
    data() {
      return {
        province:'',
        provinceAdcode:'',
        adList: [
            {
              icon: 'https://oss.yanxin7.cn/2023-08-22/eacc98c021f0bb4cd0759763f1d581e2',
              path: 'https://www.bangjiantong.com',
            },
            {
              icon: 'https://oss.yanxin7.cn/2023-08-22/eacc98c021f0bb4cd0759763f1d581e2',
              path: 'https://www.bangjiantong.com',
            },
        ],
      };
    },
    mounted(){
      document.title = '企业查询-首页'
    },
    methods: {
      toAdDetail(item) {
        if (item.path) {
          window.open(item.path)
        }
      },
      regionChange(obj) {
        // let obj = {
        //     levelType: type, // 登记类型 china:全国，province：省级，city：市级
        //     name: '', // 名称,例如 全国、广西、南宁
        //     adcode: 0, // 行政编码
        // }
        this.province=obj.name
        this.provinceAdcode=obj.adcode?obj.adcode:''
        console.log('---在home处理地区变化', obj)
      }
    },
  };
</script>

<style scoped>


</style>

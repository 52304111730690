<template>
    <div class="home-banner-bg">
        <div style="justify-content: center;display: flex;flex-direction: column;align-items: center;width: 100%;">
            <div class="" style="margin-top: 80px;">
                <BannerTitleItem></BannerTitleItem>
            </div>
            <div class="" style="margin-top: 80px;display: flex;flex-direction: column;align-items: flex-start;">
                <div class="tab-list">
                    <div class="tab-item" v-for="(item, index) in tabList" :key="index"
                        :class="index === currentTabIndex && 'tab-item-active'" @click="tabValueChange({item, index})">
                        {{ item.name }}
                    </div>
                </div>
                <div class="a-flex-row">
                    <div class="a-flex-row query-input-box" style="">
                        <el-input class="input-text" v-model="inputText" :placeholder="placeholderText"></el-input>
                        <div class="query-btn" @click="clickQueryBtn">
                            一键查询
                        </div>
                    </div>
                    <div class="smart-btn" style="margin-left: 10px;" @click="clickSmartBtn">
                        智能查询
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BannerTitleItem from '../../components/banner-title-item/index.vue'
    export default {
        components: {
            BannerTitleItem,
        },
        data() {
            return {
                tabList: [{
                    name: '施工企业',
                    path: '/qualification/index',
                    placeholder:'请输入企业名称',
                },
                {
                    name: '从业人员',
                    path: '/personnel/index',
                    placeholder:'请输入人员姓名',
                },
                {
                    name: '建设项目',
                    path: '/project/index',
                    placeholder:'请输入项目名称',
                },
                {
                    name: '诚信记录',
                    path: '',
                    placeholder:'请输入记录主体',
                },
                {
                    name: '业主单位',
                    path: '',
                    placeholder:'请输入业主单位名称',
                },
                {
                    name: '信用分',
                    path: '/creditscore/index',
                    placeholder:'请输入企业名称（信用分）',
                },],
                path:'/qualification/index',
                currentTabIndex: 0,
                inputText: '',
            }
        },
        computed: {
            placeholderText() {
                return this.tabList[this.currentTabIndex]?.placeholder
            }
        },
        mounted() {

        },
        methods: {

            tabValueChange({ item, index }) {
                this.currentTabIndex = index
                this.path=item.path
            },

            clickQueryBtn() {
                console.log('===!this.path',!this.path)
                if (!this.path) {
                    return this.$message({
                        message: '功能开发中,敬请期待~',
                        type:'warning',
                        center: true
                    });
                }
                this.$router.push({ path: this.path, query: { name: this.inputText } })


                console.log('---点击查询')

            },

            clickSmartBtn() {
                this.$router.push({
                    path: '/combinedQuery/combinedForm',
                    query: {},
                });
                console.log('---智能查询')
            },
        },

    }
</script>

<style scoped>
    .home-banner-bg {
        background-image: url("~@/assets/images/enterprise/banner_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
        height: 360px;
        min-width: 1520px;
    }

    .tab-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tab-item {
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 10px 16px;
        background-color: rgba(255, 255, 255, 0.2);
        margin-right: 4px;
        text-align: center;
        border-radius: 4px 4px 0px 0px;
        cursor: pointer;
    }

    .tab-item-active {
        background-color: rgba(255, 255, 255, 1);
        color: #007EF2;
    }

    .a-flex-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .query-input-box {
        background-color: #FFFFFF;
        padding: 4px;
        width: 900px;
        min-height: 50px;
        border-radius: 0px 4px 4px 4px;
    }

    .input-text {
        flex-grow: 1;
        font-size: 20px;
    }

    .query-input-box ::v-deep .el-input__inner {
        border: 0;
    }

    .query-btn,
    .smart-btn {
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 4px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        flex-shrink: 0;
    }

    .query-btn {
        background-color: #007EF2;
    }

    .smart-btn {
        background-color: #F38A00;
    }
</style>

<template>
    <div>
        <div class="border-box">

            <div style="margin-top: 60px;margin-bottom: 30px; font-size: 24px;font-weight: bold;">建筑资讯</div>

            <div class="" style="display: flex;justify-content: space-between;font-size: 16px;">
                <div style="display: flex;">
                    <div style="margin: 14px;width: 64px;text-align: center;padding-bottom: 10px;"
                        :class="typeIndex===index&&'active'" @click="selectType({item,index})"
                        v-for="(item,index) in typeList" :key="index">{{item.industryInformationClassName}}</div>
                </div>
                <div style="font-size: 14px;color: #888888;" @click="goList">更多>></div>
            </div>

            <div style="border: solid 1px #E2E5EC;padding: 20px;display: flex;flex-wrap: wrap;" v-loading="loadding">
                <div class="newItem" style="display: flex;align-items: center;margin-bottom: 20px;"
                    v-for="(item,index) in  listData" :key="index" @click="goDetail({item})">
                    <el-image class="bannerImg" style="flex-shrink: 0;" :src="item.informationPictureUrl"></el-image>
                    <div style="text-align: left;margin-left: 20px;">
                        <div style="font-size: 16px;font-weight: bold;max-width: 500px;" class="hideline1">
                            {{item.informationTitle}}</div>
                        <div style="color: #888888;font-size: 14px;margin-top: 16px;">{{item.createTime|timeTransform()
                            }}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { industryInformation, industryInformationCalssList } from "@/api/bid.js"
    export default {
        data() {
            return {

                typeIndex: 0,
                typeList: [

                ],
                current: 1,
                size: 16,
                listData: [],
                industryInformationClassId: '-1',
                loadding: false
            };
        },
        filters: {
            timeTransform(time) {
                let newTime = new Date(time)

                let month = newTime.getMonth() < 10 ? '0' + (newTime.getMonth()+1) : newTime.getMonth()+1
                let day = newTime.getDate() < 10 ? '0' + newTime.getDate() : newTime.getDate()
                return newTime.getFullYear() + '年' + month + '月' + day + '日'
            }
        },
        created() {
            this.industryInformationCalssList()
            this.getListData()
        },
        methods: {
            goDetail({ item }) {
                this.$router.push({
                    path:'/enterprise/newsDetail',
                    query: { informationId: item.informationId, },
                });
            },
            goList() {

                this.$router.push({
                    path: "/enterprise/newsInfoLIst",

                });
            },
            async industryInformationCalssList() {
                try {

                    let { data } = await industryInformationCalssList()

                    this.typeList = data.industryInformationCalssList
                } catch (error) {

                }
            },
            selectType({ item, index }) {
                if (this.typeIndex === index) return
                this.typeIndex = index
                this.industryInformationClassId = item.industryInformationClassId
                this.listData = []
                this.getListData()

            
            },
            async getListData() {
                try {
                    this.loadding = true
                    let params = {
                        size: this.size,
                        current: this.current,
                        industryInformationClassId: this.industryInformationClassId
                    }
                    let { data } = await industryInformation(params)
                    this.listData = data.records
                 
                    this.loadding = false
                } catch (error) {
                    console.log('资讯error', error)
                    this.loadding = false
                }
            },

        }
    };
</script>

<style scoped>
    .theme-color {
        color: #1890ff;
    }

    .border-box {

        font-size: 16px;
        padding: 20px;
        color: #212121;
        margin-top: 20px;

    }

    .active {
        color: #1890FF;
        border-bottom: solid 3px #1890FF;

    }

    .bannerImg {
        width: 80px;
        height: 80px;
    }

    .newItem {
        width: calc((100% - 132px) / 2);
    }

    .newItem:nth-of-type(2n+1) {
        margin-right: 130px;
    }

    .hideline1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
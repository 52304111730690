<template>
    <div>
        <div class="border-box">
            <div class="tab-item" style="font-size: 16px;">
                <div class="theme-color">{{province?province:'全国'}}</div>
                <div style="margin-left: 22px">企业资质（2023）</div>
            </div>

            <div style="display: flex;">
                <div class="borderRight">
                    <div class="tab-item" :class="index===typeIndex&&'select-item theme-color'"
                        v-for="(item, index) in qualificationList" :key="index" @click="selectType({item,index})">
                        <img v-if="index===typeIndex"  style="width: 14px;height: 14px;margin-right: 15px;" :src="item.sicon"></img>
                        <img  v-else style="width: 14px;height: 14px;margin-right: 15px;" :src="item.icon"></img>
                       
                        <div class="">{{ item.name }}</div>
                    </div>
                </div>

                <div style="flex:1;">
                    <div class="scrollView" :infinite-scroll-disabled="disabled" v-infinite-scroll="loadData">
                        <div class="contentItem" v-for="(item,index) in quatotalList" :key="index">
                            <div style="font-weight: bold;text-align: left;">{{item.name}}</div>
                            <div style="display: flex;font-size: 14px;margin-top: 10px;">
                                <div style="display: flex;margin-right: 40px;">
                                    <div style="color: #888888;">注册企业：</div>
                                    <div @click="goList(item.name)" style="color: #1890FF;cursor: pointer;">
                                        {{item.value}}</div>
                                    <div style="color: #888888;">家</div>
                                </div>
                                <!-- <div style="display: flex;">
                                    <div style="color: #888888;">注册企业：</div>
                                    <div style="color: #1890FF;">1000</div>
                                    <div style="color: #888888;">家</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- <p v-if="loading">加载中...</p>
                    <p v-if="noMore">没有更多了</p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCommonQualificationTypes, enTypeCount } from "@/api/bid.js"
    export default {
        data() {
            return {
                qualificationList: [],
                typeIndex: 0,
                listData: [],
                count: 20,
                loading: false,
                quatotalList: [],
                total: 0,

            };
        },
        props: {
            province: {
                type: [String],
                default: () => {
                    return ''
                }
            },
            provinceAdcode: {
                type: [String,Number],
                default: () => {
                    return ''
                }
            },
        },
        watch: {
            province: {
                handler(newsVal, oldVal) {
                    console.log('===newsVal切换全国监听', newsVal)
                    this.initParams()
                },
                immediate: true
            }
        },
        computed: {
            noMore() {
                return this.count >= 50 ? true : false
            },
            disabled() {
                return this.loading || this.noMore ? true : false
            }
        },
        created() {
            this.getQuaType()
            this.enTypeCount()
        },
        methods: {
            goList(cate2name) {
                let cate1List = [this.qualificationList[this.typeIndex].name]
                let cate2List = [cate2name]
                let proAdCode = this.provinceAdcode ? this.provinceAdcode : ''
                this.$router.push({
                    path: "/qualification/index",
                    query: { cate1List, cate2List, proAdCode },
                });
            },
            //
            initParams() {
                this.typeIndex = 0
                this.enTypeCount()
            },
            //获取资质分类
            async enTypeCount() {
                try {
                    let params = {
                        proAdCode: this.provinceAdcode ? this.provinceAdcode : '',
                        province: this.province === '全国' ? '' : this.province,
                        year: '',
                    }
                    console.log('=====params', params)
                    let { data } = await enTypeCount(params);

                    console.log('999999999999999999999', data)
                    
                    this.listData = data
                    this.quatotalList = this.listData[0].list2
                    let total = 0
                    this.listData.forEach(item => {
                        item.list2.forEach(child => {
                            total = total + child.value
                        })
                    });
                    this.total = total
                    console.log('=======')

                } catch (error) {

                }
            },
            //获取一级资质类型
            async getQuaType() {
                try {
                    let { data } = await getCommonQualificationTypes();
                   let flist=data.filter(item=>item!='工程招标')
                    let list = flist.map((item, index) => {
                        return {
                            name: item
                        }
                    })
                    list.forEach((item, index) => {
                        if (index === 0) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/333d8b255ddd5bc09592fa7605ee9812'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/e061b1d494c0694fcd6c8f0b64544698'

                        } else if (index === 1) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/ad7c2328530039be15b8ebe34376ebc4'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/af3f42814e58e6c71e45e6f4dc18b029'
                        } else if (index === 2) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/52fec8eea7bdc10ebec8e76c54b81406'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/d5c7cbfe5e680645354ccf0b29518e41'
                        } else if (index === 3) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/07f0dcb0e04168cad6d1fb486bb01572'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/72e2729bdf4a65fb3bf969db34dc6d35'
                        } else if (index === 4) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/cc3689b5bb8641a8022f9038849f2d3c'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/450b29b94600492578e49e19bbb70cd7'
                        } else if (index === 5) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/a6b717a0623cc72c884694a5e20c1ebd'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/450b29b94600492578e49e19bbb70cd7'
                        } else if (index === 6) {
                            item.icon = 'https://oss.yanxin7.cn/2023-09-07/2b15eebb0c0657e6682bb5dc75d38471'
                            item.sicon = 'https://oss.yanxin7.cn/2023-09-07/cd41634ed506494b1a03cfdf72ac33f7'
                        }

                    })
                    this.qualificationList = list
                } catch (error) {

                }
            },

            selectType({ item, index }) {
                console.log("item", item)
                this.typeIndex = index
                let list = this.listData.filter(citem => citem.name === item.name)
                console.log('=-==list', list)
                this.quatotalList = list[0].list2
            },
            loadData() {

            },
        }
    };
</script>

<style scoped>
    .theme-color {
        color: #1890ff;
    }

    .tab-item {
        display: flex;
        align-items: center;
        width: 240px;
        padding-left: 20px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-weight: bold;
        font-size: 14px;

    }

    .borderRight {
        border-right: 1px solid #E2E5EC;
    }

    .select-item {
        border-right: 2px solid #1890FF;
        background: #F2F9FF;
    }

    .border-box {
        border: solid 1px #e2e5ec;
        font-size: 16px;
        padding-top: 20px;
        color: #212121;
        margin-top: 20px;
    }

    .contentItem {
        width: 33%;
        margin-bottom: 40px;

        /* height: 100px; */
    }

    .scrollView {
        display: flex;
        flex-wrap: wrap;
        padding-left: 40px;
        /* height: 450px; */
        overflow: hidden;
        overflow: scroll;
        overflow-x: hidden;

    }

    .scrollView::-webkit-scrollbar {
        width: 0px;
        height: 20px;
    }

    .scrollView::-webkit-scrollbar-thumb {
        background: #1890FF;
        border-radius: 30px;
    }

    .scrollView::-webkit-scrollbar-track {}
</style>